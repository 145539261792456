import { ReactKeycloakProvider } from "@react-keycloak/web";
import * as React from "react";
import keycloak from "../utils/KeycloakUtil";
import IUsuario from "../utils/IUsuario";
import UsuarioUtils, { usuarioTemAlgumaFuncao } from "../utils/UsuarioUtil";
import { useState } from "react";

const SESSION_STORAGE = "fccr";

export interface AppContextInterface {
  usuario: IUsuario | null;
  logout: () => void;
  hasAnyFunction: (...funcao: string[]) => boolean;
}

const AppContext = React.createContext({} as AppContextInterface);

export default AppContext;

export interface AppContextProviderProps {}

export const AppContextProvider = ({ children }: any) => {
  const [usuario, setUsuario] = useState(Object);

  const logout = React.useCallback(async () => {
    let uri = `${window.location.protocol}${window.location.host}`;
    sessionStorage.removeItem(SESSION_STORAGE);
    setUsuario(null);
    keycloak.logout({ redirectUri: uri });
  }, []);

  const hasAnyFunction = React.useCallback(
    (...funcao: string[]) => {
      return usuarioTemAlgumaFuncao(usuario, ...funcao);
    },
    [usuario]
  );

  const eventHandler = React.useCallback(
    async (event: unknown, error: unknown) => {
      if (event === "onAuthSuccess") {
        setUsuario(UsuarioUtils.getUsuario());
      }
    },
    []
  );
  const ctx = {
    usuario,
    logout,
    hasAnyFunction,
  };
  const initOptionsKeycloak = {
    onload: "check-sso",
    checkLoginIframe: false,
  };

  return (
    <AppContext.Provider value={ctx}>
      <ReactKeycloakProvider
        authClient={keycloak}
        initOptions={initOptionsKeycloak}
        onEvent={eventHandler}
      >
        {children}
      </ReactKeycloakProvider>
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  return React.useContext(AppContext);
};
