import  keycloak from './KeycloakUtil';
import IUsuario from './IUsuario';

const clientId = process.env.REACT_APP_KEYCLOAK_CLIENT_ID;


export default class UsuarioUtils {

  public static getUsuarioPorToken(token: any): IUsuario | null {
    if(!token || !token.name || !token.cpf){
      return null;
    }
    let roles = [];
    if(token['resource_access'] && token['resource_access'][`${clientId}`]) {
      roles = token['resource_access'][`${clientId}`]['roles'];
    }

    const usuario: IUsuario = {
      nome: token.name,
      cpf: token.cpf,
      email: token.email,
      roles
    }

    return usuario;
  }
  
  public static getUsuario(): IUsuario|null {
    return this.getUsuarioPorToken(keycloak.token);
  }

}

export function usuarioTemPermissao(usuario: IUsuario|null, ...roles: string[]) {
  return !!(usuario && !usuario.bloqueado && getQtdLista(usuario.roles||[], ...roles) === roles.length);
}

export function usuarioTemFuncao(usuario: IUsuario|null, ...funcoes: string[]) {
  return !!(usuario && !usuario.bloqueado && getQtdLista(usuario.funcoes||[], ...funcoes) === funcoes.length);
}

export function usuarioTemAlgumaPermissao(usuario: IUsuario|null, ...roles: string[]) {
  return !!(usuario && !usuario.bloqueado && getQtdLista(usuario.roles||[], ...roles) > 0);
}

export function usuarioTemAlgumaFuncao(usuario: IUsuario|null, ...funcoes: string[]) {
  return !!(usuario && !usuario.bloqueado && getQtdLista(usuario.funcoes||[], ...funcoes) > 0);
}

function getQtdLista(lista: string[], ...checks: string[]) {
  let count = 0;
		
  if(lista) {
    checks.forEach( ck => {
      count += lista.filter( itemLista => itemLista.toLowerCase().localeCompare(ck.toLowerCase()) === 0 ).length;
    });		
  }
		
  return count;
}
