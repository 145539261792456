import logo from "../../assets/logo-cultura-do-recife.svg";
import caranguejo from "../../assets/caranguejo.png";
import laUrsa from "../../assets/alaursa.png";
import { useLocation } from 'react-router-dom';
import useLogin from "../../hooks/useLogin";
import { Link } from "react-router-dom";
import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
} from "@chakra-ui/react";
 

const CadastroSucesso = () => {
  const location = useLocation();
  const dados = location.state as any;
  const { isAdmin } = useLogin();

  return (
    <div className="flex flex-col justify-center items-center">
      <div className="absolute h-full w-full bg-none sm:bg-brush bg-600 bg-no-repeat -left-96 -top-14" />
      <img src={logo} alt="Logo Cultura do Recife" className="w-52 h-20 mt-8" />
      <h1 className="text-4xl sm:text-5xl font-normal font-canada mt-8 mb-8">
        Cadastro Cultural
      </h1>
      <Alert
            status="success"
            variant="subtle"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            height="250px"
          >
            <AlertIcon boxSize="40px" mr={0} />
            <AlertTitle mt={4} mb={1} fontSize="lg"> </AlertTitle>
            
            {dados.codigo !==0 ? (
           <AlertTitle mt={1} fontSize="lg">
           Nº do Cadastro Cultural: {dados.codigo} - Nome: {dados.nome}
          </AlertTitle>
          
          ) : (
            <AlertTitle mt={3} mb={1} fontSize="lg"></AlertTitle>
          )}
            <AlertTitle mt={3} mb={1} fontSize="lg">
            Cadastro realizado com sucesso! 
           </AlertTitle>
           <AlertDescription maxWidth="sm">
             Seu cadastro entrará em análise.
           </AlertDescription>
            
            {isAdmin ? (
            <Link to="/admin" className="flex mb-4 bg-orange-500 text-white font-bold w-max p-2 rounded">
              Home
            </Link>
          ) : (
             <AlertTitle mt={3} mb={1} fontSize="lg"></AlertTitle>
          )}
         </Alert>
         <div
        style={{ backgroundColor: "#FFC700" }}
        className="w-full h-56 mt-20 relative bottom-0"
      >
         <div className="bg-star bg-no-repeat bg-216 min-h-full bg-center h-full relative -top-40">
          <div className="mt-8">
            <img
              src={laUrsa}
              className="w-[386px] h-[427px] z-10 absolute right-0 -bottom-20 hidden sm:block"
              alt=""
            />
            <img
              src={caranguejo}
              className="w-56 h-32 absolute left-1/2 top-10 z-10"
              style={{ transform: "translateX(-110%)" }}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CadastroSucesso;
