export const cpfMask = (value: string) => {
  if (value) {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
  }
  return "";
};

export const cnpjMask = (value: string) => {
  if (value) {
    return value
      .replace(/\D+/g, "")
      .replace(/(\d{2})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1/$2")
      .replace(/(\d{4})(\d)/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
  } 
  return "";
};

export const dateMask = (value: string) => {
  if (value) {
    var dateParts = value.split("-");
    return dateParts[2] + "/" + dateParts[1] + "/" + dateParts[0];
  }
  return "";
};
