export interface EyeProps {
  type?: "Primary" | "Second";
  size?: "sm" | "md" | "lg" | "xl";
}

export function Eye({ type = "Primary", size = "md" }: EyeProps) {
  let estilo = "";
  let width;
  let height;

  switch (type) {
    case "Primary":
      estilo = "#1474FF";
      break;
    case "Second":
      estilo = "#FFF";
      break;
  }

  switch (size) {
    case "sm":
      width = "14";
      height = "14";
      break;
    case "md":
      width = "19";
      height = "19";
      break;
    case "lg":
      width = "24";
      height = "24";
      break;
    case "xl":
      width = "32";
      height = "32";
      break;
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z"
        stroke={estilo}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
        stroke={estilo}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
