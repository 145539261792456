import { FormWrapper } from "./FormWrapper";
import { FormControl, FormLabel, Input, Select } from "@chakra-ui/react";
import { ChangeEvent, useEffect, useState } from "react";
import {
  fetchAreaAtuacao,
  fetchTempoTrabalho,
  fetchFormaTrabalho,
  fetchRendaFamiliar,
  ApiResponse, AreaAtuacaoResposta,
} from "../services/fetchApi";
import { useForm } from "react-hook-form";
import { optionsAprendizagem, optionsForum } from "./Opcoes";

type WorkData = {
  areaAtuacaoPrimaria: number | string;
  areaAtuacaoSecundaria: number | string;
  tempoTrabalho: number | string;
  formaTrabalho: number | string;
  rendaFamiliar: number | string;
  fonteAprendizagem: string;
  forum: string;
  curriculo: File | null;
  outraAreaAtuacaoPrimaria: string | null;
  outraAreaAtuacaoSecundaria: string | null;
};

type WorkFormProps = WorkData & {
  updateFields: (fields: Partial<WorkData>) => void;
};

export const WorkForm = ({
  areaAtuacaoPrimaria,
  areaAtuacaoSecundaria,
  tempoTrabalho,
  formaTrabalho,
  rendaFamiliar,
  fonteAprendizagem,
  forum,
  curriculo,
  outraAreaAtuacaoPrimaria,
  outraAreaAtuacaoSecundaria,
  updateFields,
}: WorkFormProps) => {
  const [userAreaAtuacao, setUserAreaAtuacao] = useState<AreaAtuacaoResposta[]>([]);
  const [userTempoTrabalho, setUserTempoTrabalho] = useState<ApiResponse[]>([]);
  const [userFormaTrabalho, setUserFormaTrabalho] = useState<ApiResponse[]>([]);
  const [userRendaFamiliar, setUserRendaFamiliar] = useState<ApiResponse[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setUserAreaAtuacao(await fetchAreaAtuacao());
      setUserTempoTrabalho(await fetchTempoTrabalho());
      setUserFormaTrabalho(await fetchFormaTrabalho());
      setUserRendaFamiliar(await fetchRendaFamiliar());
    };
    fetchData();
  }, []);

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { id, value } = event.target;
    updateFields({ [id]: value });
    if(id === areaAtuacaoPrimaria) updateFields({ [id]: parseInt(value) });
    if(id === areaAtuacaoSecundaria) updateFields({ [id]: parseInt(value) });
    if(id === tempoTrabalho) updateFields({ [id]: parseInt(value) });
    if(id === formaTrabalho) updateFields({ [id]: parseInt(value) });
    if(id === rendaFamiliar) updateFields({ [id]: parseInt(value) });
  };

  const { register } = useForm<WorkData>();

  const handleForumChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedForum = event.target.value;
    updateFields({ forum: selectedForum });

    if (selectedForum === "Segmento Artístico (necessário currículo com comprovações)") {
      updateFields({ curriculo: null });
    } else {
      updateFields({ curriculo: null });
    }
  };

  const userAreaAtuacaoFiltered = userAreaAtuacao.filter((item) => {
    if (forum === "Segmento Artístico (necessário currículo com comprovações)") {
      return item.tipoForum === "S";
    } else {
      return item.tipoForum === "R"
    }
  })

  return (

    
    <FormWrapper>
      <FormControl id="forum" isRequired>
        <FormLabel>Qual fórum você faz parte atualmente?</FormLabel>
        <Select
          required
          id="forum"
          className="mb-3 z-10"
          _hover={{ borderColor: "black" }}
          borderRadius="none"
          borderColor="black"
          w={{ 
            base: "250px",
            sm: "500px"
           }}
          value={forum}
          placeholder="Selecione uma opção"
          {...register("forum")}
          onChange={handleForumChange}
        >
          {optionsForum.map((forum: any) => (
            <option key={forum.id} value={forum.descricao}>
              {forum.descricao}
            </option>
          ))}
        </Select>
      </FormControl>
      <FormControl />
      <FormControl id="areaAtuacaoPrimaria" isRequired>
        <FormLabel className="max-w-xs">
          Qual a sua principal área de atuação artístico-cultural?
        </FormLabel>
        <Select
          id="areaAtuacaoPrimaria"
          value={areaAtuacaoPrimaria}
          className="mb-3"
          _hover={{ borderColor: "black" }}
          borderRadius="none"
          borderColor="black"
          w={{ 
            base: "250px",
            sm: "300px"
           }}
          placeholder="Selecione uma opção"
          {...register("areaAtuacaoPrimaria")}
          onChange={handleChange}
        >
          {userAreaAtuacaoFiltered.map((item) => (
            <option key={item.id} value={item.id} selected={item.id === areaAtuacaoPrimaria}>
              {item.descricao}
            </option>
          ))}
        </Select>
      </FormControl>
      {(areaAtuacaoPrimaria === 21 || areaAtuacaoPrimaria === "21") && (
        <FormControl id="outraAreaAtuacaoPrimaria" isRequired>
          <FormLabel>Qual a sua Área?</FormLabel>
          <Input
            isRequired
            type="text"
            id="outraAreaAtuacaoPrimaria"
            maxLength={40}
            value={outraAreaAtuacaoPrimaria ? outraAreaAtuacaoPrimaria : ""}
            _hover={{ borderColor: "black" }}
            borderRadius="none"
            borderColor="black"
            w={{ 
            base: "250px",
            sm: "300px"
           }}
            height="40px"
            size="lg"
            {...register("outraAreaAtuacaoPrimaria")}
            onChange={handleChange}
          />
        </FormControl>
      )}
      {forum === "Segmento Artístico (necessário currículo com comprovações)" && (
        <FormControl id="areaAtuacaoSecundaria">
          <FormLabel className="max-w-xs">
          Qual a sua área secundária de atuação artístico-cultural?
          </FormLabel>
          <Select
            id="areaAtuacaoSecundaria"
            value={areaAtuacaoSecundaria}
            className="mb-3"
            _hover={{ borderColor: "black" }}
            borderRadius="none"
            borderColor="black"
            w={{ 
            base: "250px",
            sm: "300px"
           }}
            placeholder="Selecione uma opção"
            {...register("areaAtuacaoSecundaria")}
            onChange={handleChange}
          >
            {userAreaAtuacaoFiltered.map((item) => (
              <option key={item.id} value={item.id}>
                {item.descricao}
              </option>
            ))}
          </Select>
        </FormControl>
        )}
      {(areaAtuacaoSecundaria === "21" || areaAtuacaoSecundaria === 21 ) && (
        <FormControl id="outraAreaAtuacaoSecundaria" isRequired>
          <FormLabel>Qual a sua Área?</FormLabel>
          <Input
            isRequired
            type="text"
            id="outraAreaAtuacaoSecundaria"
            maxLength={40}
            value={outraAreaAtuacaoSecundaria ? outraAreaAtuacaoSecundaria : ""}
            className="mb-3"
            _hover={{ borderColor: "black" }}
            borderRadius="none"
            borderColor="black"
            w={{ 
            base: "250px",
            sm: "300px"
           }}
            size="lg"
            {...register("outraAreaAtuacaoSecundaria")}
            onChange={handleChange}
          />
        </FormControl>
      )}
      <FormControl id="tempoTrabalho" isRequired>
        <FormLabel className="max-w-xs">
          Há quanto tempo começou a trabalhar na sua área artístico-cultural?
        </FormLabel>
        <Select
          id="tempoTrabalho"
          value={tempoTrabalho}
          className="mb-3"
          _hover={{ borderColor: "black" }}
          borderRadius="none"
          borderColor="black"
          w={{ 
            base: "250px",
            sm: "300px"
           }}
          placeholder="Selecione uma opção"
          {...register("tempoTrabalho")}
          onChange={handleChange}
        >
          {userTempoTrabalho.map((item) => (
            <option key={item.id} value={item.id} selected={item.descricao === tempoTrabalho}>
              {item.descricao}
            </option>
          ))}
        </Select>
      </FormControl>
      <FormControl id="formaTrabalho" isRequired>
        <FormLabel className="max-w-xs">
          Qual dessas alternativas descreve a sua forma de trabalho:
        </FormLabel>
        <Select
          id="formaTrabalho"
          value={formaTrabalho}
          className="mb-3"
          _hover={{ borderColor: "black" }}
          borderRadius="none"
          borderColor="black"
          w={{ 
            base: "250px",
            sm: "300px"
           }}
          placeholder="Selecione uma opção"
          {...register("formaTrabalho")}
          onChange={handleChange}
        >
          {userFormaTrabalho.map((item) => (
            <option key={item.id} value={item.id} selected={item.descricao === formaTrabalho}>
              {item.descricao}
            </option>
          ))}
        </Select>
      </FormControl>
      <FormControl id="rendaFamiliar" isRequired>
        <FormLabel>Qual sua renda familiar?</FormLabel>
        <Select
          id="rendaFamiliar"
          value={rendaFamiliar}
          className="mb-3"
          _hover={{ borderColor: "black" }}
          borderRadius="none"
          borderColor="black"
          w={{ 
            base: "250px",
            sm: "300px"
           }}
          placeholder={"Selecione uma opção"}
          {...register("rendaFamiliar")}
          onChange={handleChange}
        >
          {userRendaFamiliar.map((item) => (
            <option key={item.id} value={item.id}>
              {item.descricao}
            </option>
          ))}
        </Select>
      </FormControl>
      <FormControl id="fonteAprendizagem" isRequired>
        <FormLabel className="max-w-xs">
          Em geral, qual a sua principal fonte de aprendizagem artística e
          cultural?
        </FormLabel>
        <Select
          id="fonteAprendizagem"
          value={fonteAprendizagem}
          className="mb-3"
          _hover={{ borderColor: "black" }}
          borderRadius="none"
          borderColor="black"
          w={{ 
            base: "250px",
            sm: "300px"
           }}
          placeholder="Selecione uma opção"
          {...register("fonteAprendizagem")}
          onChange={handleChange}
        >
          {optionsAprendizagem.map((item) => (
            <option key={item.id} value={item.descricao}>
              {item.descricao}
            </option>
          ))}
        </Select>
      </FormControl>
    </FormWrapper>
  );
};
