import { FormWrapper } from "./FormWrapper";
import { ChangeEvent, useState } from "react";
import {
  Alert,
  AlertIcon,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { cnpjMask, cpfMask } from "../pages/admin/utils/Masks";
import { fetchCnpj } from "../services/fetchApi";
import useLogin from "../hooks/useLogin";


type UserData = {
  novoCadastroAdmin: boolean;
  cpf: string;
  nome: string;
  nomeSocial: string;
  dataNascimento: string;
  email: string;
  telefone: string;
  isPessoaJuridica: boolean;
  cnpj: string;
  cnpjExists?: boolean;
  razaoSocial: string;
  cadastroCultural: number;
};

type UserFormProps = UserData & {
  updateFields: (fields: Partial<UserData>) => void;
};

export const UserForm = ({
  novoCadastroAdmin,
  cpf,
  nome,
  nomeSocial,
  dataNascimento,
  email,
  telefone,
  isPessoaJuridica,
  cnpj,
  cnpjExists,
  razaoSocial,
  updateFields,
}: UserFormProps) => {
  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { id, value } = event.target;

    if (id === 'cpf') {
      updateFields({ [id]: value.replace(/\D/g, '') });
      return;
    }

    updateFields({ [id]: value });
  };

  const { register } = useForm<UserData>();
  const [hasCnpj, setHasCnpj] = useState(false);
  const { isAdmin } = useLogin();
  const handleCnpj = async (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    const response = await fetchCnpj(value)
    if (response) {
      setHasCnpj(true)
      updateFields({ cnpjExists: true })
    } else {
      setHasCnpj(false)
      updateFields({ cnpjExists: false })
    }
    
    if(value.length === 0) {
      setHasCnpj(false)
    }
  }


  return (
    <FormWrapper>
      <FormControl id="cpf" isRequired>
        <FormLabel>CPF</FormLabel>
        <Input
          required
          readOnly={!isAdmin}
          value={cpfMask(cpf)}
          type="text"
          id="cpf"
          className="mb-3"
          _hover={{ borderColor: "black" }}
          borderRadius="none"
          borderColor="black"
          w={{ 
            base: "250px",
            sm: "300px"
           }}
          size="lg"
          {...register("cpf")}
          onChange={handleChange}
        />
      </FormControl>
      <FormControl id="nome" isRequired>
        <FormLabel>Nome Completo</FormLabel>
        <Input
          required
          readOnly={!isAdmin}
          type="text"
          id="nome"
          value={nome}
          className="mb-3"
          _hover={{ borderColor: "black" }}
          borderRadius="none"
          borderColor="black"
          w={{ 
            base: "250px",
            sm: "300px"
           }}
          size="lg"
          {...register("nome")}
          onChange={handleChange}
        />
      </FormControl>
      <FormControl id="nomeSocial">
        <FormLabel>Nome Social / Artístico</FormLabel>
        <Input
          type="text"
          id="nomeSocial"
          value={nomeSocial}
          className="mb-3"
          _hover={{ borderColor: "black" }}
          borderRadius="none"
          borderColor="black"
          w={{ 
            base: "250px",
            sm: "300px"
           }}
          size="lg"
          {...register("nomeSocial")}
          onChange={handleChange}
        />
      </FormControl>
      <FormControl id="dataNascimento" isRequired>
        <FormLabel>Data de Nascimento</FormLabel>
        <Input
          required
          type="date"
          id="dataNascimento"
          value={dataNascimento}
          className="mb-3"
          _hover={{ borderColor: "black" }}
          borderRadius="none"
          borderColor="black"
          w={{ 
            base: "250px",
            sm: "300px"
           }}
          size="lg"
          {...register("dataNascimento")}
          onChange={handleChange}
        />
      </FormControl>
      <FormControl id="email" isRequired>
        <FormLabel>Email</FormLabel>
        <Input
          required
          type="text"
          id="email"
          value={email}
          className="mb-3"
          _hover={{ borderColor: "black" }}
          borderRadius="none"
          borderColor="black"
          w={{ 
            base: "250px",
            sm: "300px"
           }}
          size="lg"
          {...register("email")}
          onChange={handleChange}
        />
      </FormControl>
      <FormControl id="telefone" isRequired>
        <FormLabel>Telefone</FormLabel>
        <Input
          required
          type="text"
          id="telefone"
          maxLength={11}
          value={telefone}
          className="mb-3"
          _hover={{ borderColor: "black" }}
          borderRadius="none"
          borderColor="black"
          w={{ 
            base: "250px",
            sm: "300px"
           }}
          size="lg"
          {...register("telefone")}
          onChange={handleChange}
        />
      </FormControl>
      <FormControl id="isPessoaJuridica">
        <FormLabel>Representa Pessoa Jurídica?</FormLabel>
        <Checkbox
          colorScheme="green"
          size="lg"
          onChange={() => updateFields({ isPessoaJuridica: !isPessoaJuridica })}
          isChecked={isPessoaJuridica}
          className="mb-3"
        >
          Sim
        </Checkbox>   
        {isPessoaJuridica && (
          <>
          <FormControl isRequired={isPessoaJuridica}>
            <FormLabel>CNPJ</FormLabel>
            <Input
              type="text"
              id="cnpj"
              value={cnpjMask(cnpj)}
              placeholder="CNPJ"
              className="mb-3"
              _hover={{ borderColor: "black" }}
              borderRadius="none"
              borderColor="black"
              w={{ 
            base: "250px",
            sm: "300px"
           }}
              size="lg"
              {...register("cnpj")}
              onChange={handleChange}
              // fazer chamada para verificar se ja existe cadastro com esse cnpj
              onBlur={handleCnpj}
              isInvalid={hasCnpj}
            />
            {hasCnpj && (
              <Alert status="error" w={{ 
            base: "250px",
            sm: "300px"
           }}>
                <AlertIcon />
                Este CNPJ já possui representante.
              </Alert>
            )}
            </FormControl>
            <FormControl>
            <FormLabel>Razão Social</FormLabel>
            <Input
              type="text"
              id="razaoSocial"
              value={razaoSocial}
              placeholder="Razão Social"
              className="mb-3"
              _hover={{ borderColor: "black" }}
              borderRadius="none"
              borderColor="black"
              w={{ 
            base: "250px",
            sm: "300px"
           }}
              size="lg"
              {...register("razaoSocial")}
              onChange={handleChange}
            /></FormControl>
          </>
        )}
      </FormControl>
      <FormControl />
    </FormWrapper>
  );
};
