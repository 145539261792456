import { FormWrapper } from "./FormWrapper";
import { ChangeEvent, useEffect, useState } from "react";
import {
  ApiResponse,
  fetchComunidade,
  fetchEscolaridade,
  fetchGenero,
  fetchRaca,
} from "../services/fetchApi";
import { FormControl, FormLabel, Select } from "@chakra-ui/react";
import { useForm } from "react-hook-form";

type SocialData = {
  escolaridade: number;
  raca: number;
  pcd: number;
  genero: number;
  comunidade: number;
};

type SocialFormProps = SocialData & {
  updateFields: (fields: Partial<SocialData>) => void;
};

export const SocialForm = ({
  escolaridade,
  raca,
  pcd,
  genero,
  comunidade,
  updateFields,
}: SocialFormProps) => {
  const [userEscolaridade, setUserEscolaridade] = useState<ApiResponse[]>([]);
  const [userRaca, setUserRaca] = useState<ApiResponse[]>([]);
  const [userComunidade, setUserComunidade] = useState<ApiResponse[]>([]);
  const [userGenero, setUserGenero] = useState<ApiResponse[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setUserEscolaridade(await fetchEscolaridade());
      setUserRaca(await fetchRaca());
      setUserComunidade(await fetchComunidade());
      setUserGenero(await fetchGenero());
    };

    fetchData();
  }, []);

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { id, value } = event.target;
    updateFields({ [id]: parseInt(value) });
  };

  const { register } = useForm<SocialData>();

  return (
    <FormWrapper>
      <FormControl id="escolaridade" isRequired>
        <FormLabel>Escolaridade</FormLabel>
        <Select
          required
          id="escolaridade"
          value={escolaridade}
          placeholder="Selecione uma opção"
          className="mb-3"
          _hover={{ borderColor: "black" }}
          borderRadius="none"
          borderColor="black"
          w={{ 
            base: "250px",
            sm: "300px"
           }}
          size="lg"
          {...register("escolaridade", { valueAsNumber: true })}
          onChange={handleChange}
        >
          {userEscolaridade.map((item) => (
            <option key={item.id} value={item.id}>
              {item.descricao}
            </option>
          ))}
        </Select>
      </FormControl>
      <FormControl id="raca" isRequired>
        <FormLabel>Como você se reconhece enquanto Cor/Raça/Etnia?</FormLabel>
        <Select
          required
          id="raca"
          value={raca}
          placeholder="Selecione uma opção"
          className="mb-3"
          _hover={{ borderColor: "black" }}
          borderRadius="none"
          borderColor="black"
          w={{ 
            base: "250px",
            sm: "300px"
           }}
          size="lg"
          {...register("raca", { valueAsNumber: true })}
          onChange={handleChange}
        >
          {userRaca.map((item) => (
            <option key={item.id} value={item.id}>
              {item.descricao}
            </option>
          ))}
        </Select>
      </FormControl>
      <FormControl id="pcd" isRequired>
        <FormLabel>Você é pessoa com deficiência? (PCD)</FormLabel>
        <Select
          required
          id="pcd"
          value={pcd}
          placeholder="Selecione uma opção"
          className="mb-3"
          _hover={{ borderColor: "black" }}
          borderRadius="none"
          borderColor="black"
          w={{ 
            base: "250px",
            sm: "300px"
           }}
          size="lg"
          {...register("pcd", { valueAsNumber: true })}
          onChange={handleChange}
        >
          <option value={0}>Não</option>
          <option value={1}>Sim</option>
        </Select>
      </FormControl>
      <FormControl id="genero" isRequired>
        <FormLabel>Com relação ao gênero, como você se identifica?</FormLabel>
        <Select
          required
          id="genero"
          value={genero}
          placeholder="Selecione uma opção"
          className="mb-3"
          _hover={{ borderColor: "black" }}
          borderRadius="none"
          borderColor="black"
          w={{ 
            base: "250px",
            sm: "300px"
           }}
          size="lg"
          {...register("genero", { valueAsNumber: true })}
          onChange={handleChange}
        >
          {userGenero.map((item) => (
            <option key={item.id} value={item.id}>
              {item.descricao}
            </option>
          ))}
        </Select>
      </FormControl>
      <FormControl id="comunidade" isRequired>
        <FormLabel className="max-w-sm">
          Você se identifica como integrante de comunidades e povos tradicionais
          ou originários?
        </FormLabel>
        <Select
          required
          id="comunidade"
          value={comunidade}
          placeholder="Selecione uma opção"
          className="mb-3"
          _hover={{ borderColor: "black" }}
          borderRadius="none"
          borderColor="black"
          w={{ 
            base: "250px",
            sm: "300px"
           }}
          size="lg"
          {...register("comunidade", { valueAsNumber: true })}
          onChange={handleChange}
        >
          {userComunidade.map((item) => (
            <option key={item.id} value={item.id}>
              {item.descricao}
            </option>
          ))}
        </Select>
      </FormControl>
    </FormWrapper>
  );
};
