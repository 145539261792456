export interface EditProps {
  type?: "Primary" | "Second";
  size?: "sm" | "md" | "lg" | "xl";
}

export function Edit({ type = "Primary", size = "md" }: EditProps) {
  let estilo = "";
  let width;
  let height;

  switch (type) {
    case "Primary":
      estilo = "#16A34A";
      break;
    case "Second":
      estilo = "#FFF";
      break;
  }

  switch (size) {
    case "sm":
      width = "14";
      height = "14";
      break;
    case "md":
      width = "19";
      height = "19";
      break;
    case "lg":
      width = "24";
      height = "24";
      break;
    case "xl":
      width = "32";
      height = "32";
      break;
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13"
        stroke={estilo}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5 2.50001C18.8978 2.10219 19.4374 1.87869 20 1.87869C20.5626 1.87869 21.1022 2.10219 21.5 2.50001C21.8978 2.89784 22.1213 3.4374 22.1213 4.00001C22.1213 4.56262 21.8978 5.10219 21.5 5.50001L12 15L8 16L9 12L18.5 2.50001Z"
        stroke={estilo}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
