import axios, { AxiosError } from 'axios';
import keycloak from '../utils/KeycloakUtil';
import { Dados } from '../interfaces/CadastrosUsuarios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

export interface ApiResponse {
  id: number
  descricao: string
  ultimaAtualizacao?: string
}

export interface AreaAtuacaoResposta extends ApiResponse{
  codigoAreaMae: number
  tipoPessoa: string
  tipoForum: string
}

const fetchData = async <T>(url: string): Promise<T[]> => {
  try {
    const response = await instance.get<ApiResponse[]>(url, {
      headers: {
        'Authorization': `Bearer ${keycloak.token}`,
      }
    });
    return response.data as T[];
  } catch (error) {
    return [];
  }
}

export const fetchRaca = async () => {
  return fetchData<ApiResponse>('/raca');
};

export const fetchComunidade = async () => {
  return fetchData<ApiResponse>('/comunidade');
};

export const fetchRendaFamiliar = async () => {
  return fetchData<ApiResponse>('/rendafamiliar');
};

export const fetchEscolaridade = async () => {
  return fetchData<ApiResponse>('/escolaridade');
};

export const fetchTempoTrabalho = async () => {
  return fetchData<ApiResponse>('/tempotrabalho');
};

export const fetchGenero = async () => {
  return fetchData<ApiResponse>('/genero');
};

export const fetchFormaTrabalho = async () => {
  return fetchData<ApiResponse>('/formatrabalho');
};

export const fetchAreaAtuacao = async () => {
  return fetchData<AreaAtuacaoResposta>('/areaatuacao');
}

// export const fetchAreaAtuacao = async (tipo: string) => {
//   return fetchData<AreaAtuacaoResposta>(`/areaatuacao?tipoforum=${tipo}`);
// }

export const fetchListarCadastro = async <Page>(
  pageSize: number, pagina: number, filterField: string, filterValue: string, orderBy: string, sort: string
): Promise<Page| null> => {    
    try {
      let url = `/cadastro/listar?tamanhoPagina=${pageSize}&pagina=${pagina-1}&orderBy=${orderBy}&sort=${sort}`
      if (filterField && filterValue) {
        url = `${url}&${filterField}=${filterValue}`;
      }

      const response = await instance.get<Page>(
        url, 
        {
          headers: {
            'Authorization': `Bearer ${keycloak.token}`,
          }
        }
      );

      return response.data as Page;
    } catch (error) {
      return null;
    }
}

export const fetchCadastro = async (cpf: string): Promise<Dados | null> => {
  try {
    const response = await instance.get<Dados>(`/cadastro/buscar?cpf=${cpf}`, {
      headers: {
        'Authorization': `Bearer ${keycloak.token}`,
      }
    });
    return response.data as Dados;
  } catch (error) {
    return null;
  }

}

export const downloadFile = (nome: string): any => {
  try {
    const response = instance.get(
      `/cadastro/buscarimagem?nomeobjeto=${nome}`,
      {
        headers: {
          'Authorization': `Bearer ${keycloak.token}`
        },
        responseType: 'arraybuffer'
      }
    ).then((response) => {
      const blob = new Blob([response.data]);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', nome);
      document.body.appendChild(link);
      link.click();
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export const submitForm = async (formData: FormData) => {
  try {
    const response = await instance.post(
      '/cadastrar',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${keycloak.token}`
        }
      }
    );
    return response;
  } catch (error) {
    if (error instanceof AxiosError) {
      console.log(error);
      return error;
    }    
    return null;
  }
};

export const submitEdit = async (id: number, status: string, forum : string, idAreaP: number, idAreaS : number) => {
  if(forum === "Região Política Administrativa (RPA)") {idAreaS = 28;}
  
  try {
    const response = await instance.post(
      `/cadastro/atualizarstatus?codigoCadastro=${id}&status=${status}&forum=${forum}&codigoAreaP=${idAreaP}&codigoAreaS=${idAreaS}`,
      {},
      {
        headers: {
          'Authorization': `Bearer ${keycloak.token}`
        }
      }
    );
    
    if(response.status !== 200 && response.status !== 201 && response.status !== 204) {
      alert('Erro ao atualizar o status, por favor tente novamente mais tarde!')
      throw new Error('Erro ao atualizar o status');
    } else {
      return response;
    }
  } catch (error) {
    throw error;
  }
}

export const downloadExcel = async () => {
  const response = await instance.get('/cadastro/exportar', {
      responseType: 'blob',
      headers: {
        'Authorization': `Bearer ${keycloak.token}`
      }
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `cadastros.xlsx`);
    document.body.appendChild(link);
    link.click();

    return response;
    })

    return response;
}

export const fetchCnpj = async (cnpj: string) => {
  try {
    const response = await instance.get<any>(`/cadastro/buscar?cnpj=${cnpj}`, {
      headers: {
        'Authorization': `Bearer ${keycloak.token}`,
      }
    });
    return response.data as any;
  } catch (error) {
    return null;
  }
}

export const fetchOldFiles = async (id: number) => {
  try {
    const response = await instance.get<any>(`arquivosantigos?cadastrocultural=${id}`,{
      headers: {
        'Authorization': `Bearer ${keycloak.token}`,
      }
    });
    return response.data as any;
  } catch (error) {
    return null;
  }
}
