import { FormWrapper } from "./FormWrapper";
import { ChangeEvent } from "react";
import { Button, FormControl, FormLabel, Input } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { fetchCep } from "../services/fetchCep";
import { downloadFile } from "../services/fetchApi";

type Endereco = {
  cep: string;
  logradouro: string;
  localidade?: string;
  bairro: string;
  numero: string;
  complemento: string | null;
  uf: string;
  cepComprovante: File | null;
};

type AddressData = {
  endereco: Endereco;
};

type AddressFormProps = AddressData & {
  updateFields: (fields: Partial<AddressData>) => void;
};

export const AddressForm = ({ endereco, updateFields }: AddressFormProps) => {
  const handleChange = async (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { id, value, type } = event.target;
    if (type === "file") {
      const files = (event as ChangeEvent<HTMLInputElement>).target.files;
      if (files && files.length > 0) {
        const file = files[0];
        const maxSize = 10485760;
        if (file.size > maxSize) {
            alert('Tamanho do arquivo excede o limite de 10Mb!');
            event.target.value = ""
        }else{
        updateFields({ endereco: { ...endereco, cepComprovante: file } });
      }
      }
    } else {
      const updatedEndereco = { ...endereco, [id]: value };
      updateFields({ endereco: updatedEndereco });
    }
  };

  const { register } = useForm<AddressData>();

  return (
    <FormWrapper>
      <FormControl id="cep" isRequired>
        <FormLabel>CEP</FormLabel>
        <Input
          required
          type="text"
          id="cep"
          value={endereco.cep}
          placeholder="00000-000"
          maxLength={9}
          className="mb-3"
          _hover={{ borderColor: "black" }}
          borderRadius="none"
          borderColor="black"
          w={{ 
            base: "250px",
            sm: "300px"
           }}
          size="lg"
          {...register("endereco.cep")}
          onChange={async (event) => {
            const cepValue = event.target.value;
            if (cepValue.length === 8) {
              const fetchedCep = await fetchCep(cepValue);
              updateFields({ endereco: { ...endereco, ...fetchedCep } });
            } else {
              updateFields({ endereco: { ...endereco, cep: cepValue } });
            }
          }}
        />
      </FormControl>
      <FormControl id="logradouro" isRequired>
        <FormLabel>Logradouro</FormLabel>
        <Input
          required
          type="text"
          id="logradouro"
          value={endereco.logradouro}
          className="mb-3"
          _hover={{ borderColor: "black" }}
          borderRadius="none"
          borderColor="black"
          w={{ 
            base: "250px",
            sm: "300px"
           }}
          size="lg"
          {...register("endereco.logradouro")}
          onChange={handleChange}
        />
      </FormControl>
      <FormControl id="cidade" isRequired>
        <FormLabel>Cidade</FormLabel>
        <Input
          required
          type="text"
          id="cidade"
          value={endereco.localidade}
          className="mb-3"
          _hover={{ borderColor: "black" }}
          borderRadius="none"
          borderColor="black"
          w={{ 
            base: "250px",
            sm: "300px"
           }}
          size="lg"
          {...register("endereco.localidade")}
          onChange={(event) => {
            const newValue = event.target.value;
            updateFields({ endereco: { ...endereco, localidade: newValue } }); // Update the state with the new value
          }}
        />
      </FormControl>
      <FormControl id="bairro" isRequired>
        <FormLabel>Bairro</FormLabel>
        <Input
          required
          type="text"
          id="bairro"
          value={endereco.bairro}
          className="mb-3"
          _hover={{ borderColor: "black" }}
          borderRadius="none"
          borderColor="black"
          w={{ 
            base: "250px",
            sm: "300px"
           }}
          size="lg"
          {...register("endereco.bairro")}
          onChange={handleChange}
        />
      </FormControl>
      <FormControl id="numero" isRequired>
        <FormLabel>Número</FormLabel>
        <Input
          required
          type="text"
          id="numero"
          value={endereco.numero}
          className="mb-3"
          _hover={{ borderColor: "black" }}
          borderRadius="none"
          borderColor="black"
          w={{ 
            base: "250px",
            sm: "300px"
           }}
          size="lg"
          {...register("endereco.numero")}
          onChange={handleChange}
        />
      </FormControl>
      <FormControl id="complemento">
        <FormLabel>Complemento</FormLabel>
        <Input
          type="text"
          id="complemento"
          value={endereco.complemento!}
          className="mb-3"
          _hover={{ borderColor: "black" }}
          borderRadius="none"
          borderColor="black"
          w={{ 
            base: "250px",
            sm: "300px"
           }}
          size="lg"
          {...register("endereco.complemento")}
          onChange={handleChange}
        />
      </FormControl>
      <FormControl id="estado" isRequired>
        <FormLabel>Estado</FormLabel>
        <Input
          required
          type="text"
          id="estado"
          value={endereco.uf}
          className="mb-3"
          _hover={{ borderColor: "black" }}
          borderRadius="none"
          borderColor="black"
          w={{ 
            base: "250px",
            sm: "300px"
           }}
          size="lg"
          {...register("endereco.uf")}
          onChange={handleChange}
        />
      </FormControl>
      <FormControl id="comprovante" isRequired>
        <FormLabel>Comprovante de Residência (limite de 10Mb)</FormLabel>
        {endereco.cepComprovante ? (
          <>
            <Input
              value={
                endereco.cepComprovante instanceof File
                  ? endereco.cepComprovante.name
                  : typeof endereco.cepComprovante === "object"
                  ? "Object"
                  : String(endereco.cepComprovante)
              }
              isReadOnly
              onClick={() => {
                if (typeof endereco.cepComprovante === "string") {
                  downloadFile(endereco.cepComprovante);
                }
              }}
              className="cursor-pointer"
              w={{ 
            base: "250px",
            sm: "300px"
           }}
              height="48px"
              borderRadius="none"
              borderColor="black"
              size="md"
            />
            <Button
              className="mt-3"
              colorScheme="red"
              onClick={() =>
                updateFields({
                  endereco: { ...endereco, cepComprovante: null },
                })
              }
            >
              Remover arquivo
            </Button>
          </>
        ) : (
          <Input
            type="file"
            accept=".pdf, .jpeg, .png"
            id="comprovante"
            className="mb-3"
            w={{ 
            base: "250px",
            sm: "300px"
           }}
            height="48px"
            borderRadius="none"
            borderColor="black"
            size="md"
            {...register("endereco.cepComprovante")}
            onChange={handleChange}
          />
        )}
      </FormControl>
    </FormWrapper>
  );
};
