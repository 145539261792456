import { BrowserRouter } from 'react-router-dom';
import { AppContextProvider } from './context/AppContext';
import Rotas from './Rotas';
import './index.css';

function App() {
   return (
    <div className='body bg-gray-f6'>
      <BrowserRouter basename='/'>
        <AppContextProvider>
          <Rotas />
        </AppContextProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
