import { Route, Switch } from 'react-router-dom';
import  CadastroCultural  from './pages/cadastrocultural/CadastroCultural';
import PaginaProtegida from './components/PaginaProtegida';
import CadastroSucesso from './pages/cadastrocultural/CadastroSucesso';
import LoginPage from './pages/login/LoginPage';
import SemPermissaoPage from './pages/login/SemPermissaoPage';
import Admin from './pages/admin/Admin';

const Rotas = () => {      
  return (
    <Switch>
      <Route path="/login">
        <LoginPage />
      </Route>
      <Route path="/" exact>
        <LoginPage />
      </Route>
      <Route path="/cadastrocultural/ok">
        <CadastroSucesso />
      </Route>

      <Route path="sem_permissao" component={SemPermissaoPage}/>

      <PaginaProtegida path="/cadastrocultural" cadastroCultural={true}>
        <CadastroCultural />
      </PaginaProtegida>

      <PaginaProtegida path="/admin">
          <Admin />
      </PaginaProtegida>
    </Switch>
  )
    
}

export default Rotas