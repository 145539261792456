import { FormEvent, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Pagination,
  usePagination,
  PaginationNext,
  PaginationPage,
  PaginationPrevious,
  PaginationContainer,
  PaginationPageGroup,
  PaginationSeparator,
} from "@ajna/pagination";
import logo from "../../assets/logo-cultura-do-recife.svg";
import caranguejo from "../../assets/caranguejo.png";
import laUrsa from "../../assets/alaursa.png";
import { FcEngineering } from "react-icons/fc";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Select,
  Alert,
  AlertIcon,
  Box,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { Eye } from "../../icons/Eye";
import { Edit } from "../../icons/Edit";
import {
  ApiResponse,
  AreaAtuacaoResposta,
  downloadExcel,
  downloadFile,
  fetchAreaAtuacao,
  fetchListarCadastro,
  fetchOldFiles,
  submitEdit,
} from "../../services/fetchApi";
import { Page, Dados } from "../../interfaces/CadastrosUsuarios";
import keycloak from "../../utils/KeycloakUtil";
import { cpfMask, dateMask } from "./utils/Masks";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { optionsForum } from "../../components/Opcoes";

const Admin = () => {
  const [page, setPage] = useState<Page | null>();
  const [itensTotal, setItensTotal] = useState<number | undefined>(undefined);
  const [order, setOrder] = useState<string>("documento");
  const [toggle, setToggle] = useState<boolean>(false);
  const [filterField, setFilterField] = useState<string>("");
  const [filterValue, setFilterValue] = useState<string>("");
  const [selectedUser, setSelectedUser] = useState<any>(null);
  //const [forumUpdate, setForumUpdate]  = useState<string>("");
  const [selectedUserStatusChanged, setSelectedUserStatusChanged] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  //const [userAreaAtuacao, setUserAreaAtuacao] = useState<ApiResponse[]>([]);
  const [userAreaAtuacao, setUserAreaAtuacao] = useState<AreaAtuacaoResposta[]>([]);
  const [userOldFiles, setUserOldFiles] = useState<ApiResponse[]>([]);
  const [dataUpdate, setDataUpdate] = useState(false);

  // constants
  const outerLimit = 2;
  const innerLimit = 2;

  // pagination hook
  const {
    pages,
    pagesCount,
    currentPage,
    setCurrentPage,
    isDisabled,
    pageSize,
  } = usePagination({
    total: itensTotal,
    limits: {
      outer: outerLimit,
      inner: innerLimit,
    },
    initialState: {
      pageSize: 15,
      isDisabled: false,
      currentPage: 1,
    },
  });

  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();
  const {
    isOpen: isViewOpen,
    onOpen: onViewOpen,
    onClose: onViewClose,
  } = useDisclosure();

  useEffect(() => {
    const fetchData = async () => {
      setUserAreaAtuacao(await fetchAreaAtuacao());

      const chamada: Page | null = await fetchListarCadastro(
        pageSize,
        currentPage,
        filterField,
        filterValue,
        order,
        toggle ? "desc" : "asc"
      );

      if (chamada) {
        //console.log(chamada);
        setPage(chamada);
        setItensTotal(chamada.totalRegistros);
      }
    };
    fetchData();
  }, [pageSize, currentPage, filterField, filterValue, order, toggle, dataUpdate]);

  const header = ["documento", "nº de inscrição", "nome", "area de atuação", "forum", "data inscrição", "status"];

  const handleEditClick = (id: number) => {
    const user = page?.dados.find(
      (item: Dados) => item.cadastroCultural === id
    );
    setSelectedUser(user);
    onEditOpen();
  };

  const handleViewClick = async (id: number) => {
    const user = page?.dados.find(
      (item: Dados) => item.cadastroCultural === id
    );
    setSelectedUser(user);

    const arrayOlds = await fetchOldFiles(id);
    setUserOldFiles(arrayOlds);
    onViewOpen();
  };

  const handleEditSubmit = async (id: number, status: string, forum : string, idAreaP: number, idAreaS : number) => {
    if(idAreaS === undefined){
      idAreaS = 29;
    }
    const user = page?.dados.find(
      (item: Dados) => item.cadastroCultural === id
    );
    setSelectedUser(user);
    const response = await submitEdit(id, status, forum, idAreaP, idAreaS);
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      setSelectedUserStatusChanged(true);
      //setForumUpdate("");
      setDataUpdate(!dataUpdate);
      setTimeout(() => {
        setSelectedUserStatusChanged(false);
      }, 2000);
    }
    return response;
  };

  const handleStatusChange = (event: FormEvent) => {
    const newStatus = (event.target as HTMLInputElement).value;
    const updatedUser = { ...selectedUser };
    updatedUser.status = newStatus;
    setSelectedUser(updatedUser);
  };

  const handleAreaPrimariaChange = (event: FormEvent) => {
    const updatedUser = { ...selectedUser };
    if (updatedUser.formularioCultura.areaAtuacao === null){
       updatedUser.formularioCultura.areaAtuacao = {};
    }
    const newAreaP = (event.target as HTMLInputElement).value;
    updatedUser.formularioCultura.areaAtuacao.id = newAreaP;
    setSelectedUser(updatedUser);
  };

  const handleAreaSecundariaChange = (event: FormEvent) => {
    const updatedUser = { ...selectedUser };
    if (updatedUser.formularioCultura.areaAtuacaoSecundaria === null){
      updatedUser.formularioCultura.areaAtuacaoSecundaria = {};
   }
    const newAreaS = (event.target as HTMLInputElement).value;
    updatedUser.formularioCultura.areaAtuacaoSecundaria.id = newAreaS;
    setSelectedUser(updatedUser);
  };

  const handleForumChange = (event: FormEvent) => {
    const newForum = (event.target as HTMLInputElement).value;
    const updatedUser = { ...selectedUser };
    updatedUser.formularioCultura.forumPertencente = newForum;
    setSelectedUser(updatedUser);
    //setForumUpdate(newForum);
  };

  const exportToExcel = async () => {
    setIsDownloading(true);
    const response = await downloadExcel();
    if (response?.status === 200) {
      setIsDownloading(false);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    keycloak.logout();
  };

  // handlers
  const handlePageChange = (nextPage: number): void => {
    // -> request new data using the page number
    setCurrentPage(nextPage);
  };

  const handleStatusDescritivo = (value: string) => {
    const statusMapping: any = {
      E: "Em análise",
      P: "Pendente",
      S: "Ativo",
      N: "Inativo",
    };

    return statusMapping[value];
  };

  const userAreaAtuacaoFiltered = userAreaAtuacao.filter((item) => {
    if (selectedUser?.formularioCultura?.forumPertencente === "Segmento Artístico (necessário currículo com comprovações)") {
      return item.tipoForum === "S";
    } else {
      return item.tipoForum === "R"
    }
  })

  
  const handleOrderChange = (nomeColuna: any) => {
  if (nomeColuna === "documento" || nomeColuna === "nome" || nomeColuna === "nº de inscrição") {    
    if (order === nomeColuna) {
      setToggle(!toggle);
    } else {
      setOrder(nomeColuna);
      setToggle(false);
      }
    }
  };

  const handleFiltroChange = (nomeColuna: any, key: string) => {
    
    if (key === "Enter") {
      setCurrentPage(1);

      let field = document.getElementById(
        "s-" + nomeColuna
      ) as HTMLInputElement;
      let value = field.value;

      var searchs = document.getElementsByClassName("search");
      for (var i = 0; i < searchs.length; i++) {
        let item: any = searchs.item(i);
        item.value = "";
      }
      field.value = value;

      if(nomeColuna === "area de atuação") {
        nomeColuna = "areaatuacao"
      }

      if (nomeColuna === "nº de inscrição"){
        nomeColuna = "cadastroCultural"
      }

      if (value) {
        setFilterField(nomeColuna);
        setFilterValue(value);
      } else {
        setFilterField("");
        setFilterValue("");
      }
    }

  };

  const renderSearch = (nomeColuna: string) => {

    if (nomeColuna === "forum") {
      return (
        <select
          id={`s-${nomeColuna}`}
          onChange={(e) => handleFiltroChange(nomeColuna, "Enter")}
          className="search mt-1 p-1 rounded-sm border border-black w-full h-6 bg-white"
        >
          <option value="">** Todos **</option>
          {optionsForum.map((forum: any) => (
            <option key={forum.id} value={forum.descricao}>
              {forum.descricao}
            </option>
          ))}
        </select>
      );
    }

    if (nomeColuna === "area de atuação") {
      return (
        <select
          id={`s-${nomeColuna}`}
          onChange={(e) => handleFiltroChange(nomeColuna, "Enter")}
          className="search mt-1 p-1 rounded-sm border border-black w-full h-6 bg-white"
        >
          <option value="">** Todos **</option>
          {userAreaAtuacao.map((item) => (
            <option key={item.id} value={item.id}>
              {item.descricao}
            </option>
          ))}
        </select>
      );
    }

    if (nomeColuna === "status") {
      return (
        <select
          id={`s-${nomeColuna}`}
          onChange={(e) => handleFiltroChange(nomeColuna, "Enter")}
          className="search mt-1 p-1 rounded-sm border border-black w-full h-6 bg-white"
        >
          <option value="">** Todos **</option>
          <option value="E">Em análise</option>
          <option value="P">Pendente</option>
          <option value="S">Ativo</option>
          <option value="N">Inativo</option>
        </select>
      );
    }

    if (nomeColuna === "data inscrição") {
      return " "
    }

    if (nomeColuna !== "telefone") {
      return (
        <input
          id={`s-${nomeColuna}`}
          onKeyUp={(e) => handleFiltroChange(nomeColuna, e.key)}
          className="search mt-1 p-1 rounded-sm border border-black w-full h-6"
        />
      );
    }

    return "";
  };

  return (
    <div className="flex flex-col justify-center items-center gap-10">
      <div className="absolute h-full w-full bg-none bg-600 bg-no-repeat -left-96 -top-14 z-10 sm:bg-brush" />
      <img src={logo} alt="Logo Cultura do Recife" className="w-52 h-20 mt-8" />
      <h1 className="text-5xl font-normal font-canada mt-2 z-20">
        Cadastro Cultural
      </h1>
      <div className="flex justify-center gap-10 container">
      <Link to={{ 
          pathname: "/cadastrocultural",
          state: {
            cpf: keycloak?.tokenParsed?.preferred_username,
            novoCadastroAdmin: true                     
          }
         }} className="z-20 flex justify-center">
          <Button
            bg="black"
            textColor="white"
            borderRadius="none"
            width="180px"
            height="50px"
            _hover={{ bg: "red-f2", transition: "all 150ms ease-in-out" }}
            fontWeight="500"
            className="py-4 hover:bg-red-f2 transition-all duration-150 ease-in-out mb-3"
          >
            Cadastrar novo usuário
          </Button>
        </Link>
        <Link to={{ 
          pathname: "/cadastrocultural",
          state: {
            cpf: keycloak?.tokenParsed?.preferred_username,
            novoCadastroAdmin: false
          }
         }} className="z-20 flex justify-center">
          <Button
            bg="black"
            textColor="white"
            borderRadius="none"
            width="180px"
            height="50px"
            _hover={{ bg: "red-f2", transition: "all 150ms ease-in-out" }}
            fontWeight="500"
            className="py-4 hover:bg-red-f2 transition-all duration-150 ease-in-out mb-3"
          >
            Cadastro Cultural
          </Button>
        </Link>
        <Button
          type="button"
          onClick={handleLogout}
          bg="black"
          textColor="white"
          borderRadius="none"
          width="60px"
          height="50px"
          _hover={{ bg: "red-f2", transition: "all 150ms ease-in-out" }}
          fontWeight="500"
          className="z-20 py-4 hover:bg-red-f2 transition-all duration-150 ease-in-out"
        >
          Sair
        </Button>
      </div>
      <div className="z-50 flex flex-col items-center min w-full">
        <Button
          onClick={exportToExcel}
          className="mb-3 hover:bg-red-f2 transition-all duration-150 ease-in-out"
          bg="black"
          textColor="white"
          borderRadius="none"
          width="180px"
          height="40px"
          _hover={{ bg: "red-f2", transition: "all 150ms ease-in-out" }}
          fontWeight="500"
          isLoading={isDownloading}
          loadingText="Baixando..."
        >
          Exportar para Excel
        </Button>
        <TableContainer
          borderColor="black"
          borderWidth="2px"
          className="bg-white"
        >
          <Table variant="unstyled" colorScheme="teal">
            <Thead>
              <Tr>
                {header.map((header: string, index: number) => (
                  <Th key={index} className="align-top">
                    <div className="flex flex-col">
                      <label
                        id={`l-${header}`}
                        onClick={() => handleOrderChange(header)}
                        className="flex items-center cursor-pointer py-1"
                      >
                        {header}
                        {order === header && (
                          <span className="ml-1">
                            {toggle ? <AiFillCaretDown /> : <AiFillCaretUp />}
                          </span>
                        )}
                      </label>
                      {renderSearch(header)}
                    </div>
                  </Th>
                ))}
                <Th className="flex justify">Ações</Th>
              </Tr>
            </Thead>
            <Tbody>
              {page?.dados.map((item: any) => (
                <Tr key={item.cadastroCultural}>
                  <Td>
                    {cpfMask(item.pessoaFisica?.cpf)}
                  </Td>
                  <Td>
                    {item.cadastroCultural}
                  </Td>
                  <Td>
                    {item.pessoaFisica?.nome}
                  </Td>
                  <Td>{item.formularioCultura?.areaAtuacao?.descricao}</Td>
                  <Td>{item.formularioCultura?.forumPertencente}</Td>
                  <Td>{dateMask(item.dataInscricao)}</Td>
                  <Td>
                    {item.status
                      ? handleStatusDescritivo(
                          item.status
                        )
                      : "Status não disponível"}
                  </Td>
                  <Td className="px-6 py-4 flex justify-end whitespace-nowrap">
                    <button
                      onClick={() =>
                        handleEditClick(item.cadastroCultural)                        
                      }
                      title="Alterar Status"
                    >
                      <Edit size="lg" />
                    </button>
                    <span className="px-2"></span>
                    <button
                      onClick={() =>
                        handleViewClick(item.cadastroCultural)}
                        title="Visualizar Cadastro"
                    >
                      <Eye size="lg" />
                         </button>
               <Link to={{ 
                    pathname: "/cadastrocultural",
                   state: {
            cpf: item.pessoaFisica?.cpf,
            novoCadastroAdmin: false
          }
         }}
          >
          <Button  
          bg="white"
          borderRadius="none"
          _hover={{ bg: "white"}}
          title="Editar Cadastro"
          >
            <FcEngineering 
            size="1.9em"
            color="yellow"/>
         </Button>
          </Link>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>

        <Pagination
          pagesCount={pagesCount}
          currentPage={currentPage}
          isDisabled={isDisabled}
          onPageChange={handlePageChange}
        >
          <PaginationContainer
            align="center"
            justify="space-evenly"
            p={4}
            w="full"
          >
            <PaginationPrevious
              _hover={{
                bg: "yellow.400",
              }}
              bg="yellow.300"
            >
              <Text>Anterior</Text>
            </PaginationPrevious>
            <PaginationPageGroup
              isInline
              align="center"
              separator={
                <PaginationSeparator
                  isDisabled
                  bg="blue.300"
                  fontSize="sm"
                  w={7}
                  jumpSize={11}
                />
              }
            >
              {pages.map((page: number) => (
                <PaginationPage
                  w={7}
                  bg="red.300"
                  key={`pagination_page_${page}`}
                  page={page}
                  fontSize="sm"
                  _hover={{
                    bg: "#2c8c48",
                  }}
                  _current={{
                    bg: "#2c8c48",
                    fontSize: "sm",
                    w: 7,
                  }}
                />
              ))}
            </PaginationPageGroup>
            <PaginationNext
              _hover={{
                bg: "yellow.400",
              }}
              bg="yellow.300"
            >
              <Text>Próximo</Text>
            </PaginationNext>
          </PaginationContainer>
        </Pagination>

        <Modal isOpen={isEditOpen} onClose={onEditClose}>
          <ModalOverlay />
          <ModalContent>

            <ModalHeader>Alterar Status</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl isReadOnly className="">
              <FormLabel>Nº de Inscrição</FormLabel>
                <Input
                  type="text"
                  value={selectedUser?.pessoaFisica?.cadastroCultural}
                />
                <FormLabel>CPF</FormLabel>
                <Input
                  type="text"
                  value={selectedUser?.pessoaFisica?.cpf}
                />
                <FormLabel>
                  {selectedUser?.pessoaFisica?.cnpj ? "Nome do responsável" : "Nome"}
                </FormLabel>
                <Input
                  type="text"
                  value={
                    selectedUser?.pessoaFisica?.nome
                  }
                />
                
                <FormLabel>Email</FormLabel>
                <Input type="text" value={selectedUser?.pessoaFisica.email} />
               
                <Box className="p-1 mt-1">
                        <FormLabel>Fórum</FormLabel>
                        <Select
                        onChange={handleForumChange}
                        defaultValue={selectedUser?.formularioCultura?.forumPertencente}
                        placeholder="Selecione uma opção"
                     >
                     {optionsForum.map((forum: any) => (
            <option key={forum.id} value={forum.descricao} >
              {forum.descricao}
            </option>
            
            ))}
                      </Select>
                      </Box>
                      <Box className="p-1 mt-1">
                        <FormLabel>Área de atuação</FormLabel>
                        <Select
                          defaultValue={selectedUser?.formularioCultura?.areaAtuacao?.id}
                          placeholder="Selecione uma opção"
                          onChange={handleAreaPrimariaChange}
                        >
                        {userAreaAtuacaoFiltered.map((item) => (
            <option key={item.id} value={item.id}  >
              {item.descricao}
            </option>
          ))}
                       </Select>
                     </Box>
                     { selectedUser?.formularioCultura?.forumPertencente === "Segmento Artístico (necessário currículo com comprovações)" ? (
                    <Box className="p-1 mt-1">
                        <FormLabel>Área de atuação secundária</FormLabel>
                        <Select
                           defaultValue={selectedUser?.formularioCultura?.areaAtuacaoSecundaria?.id}
                           placeholder="Selecione uma opção"
                           onChange={handleAreaSecundariaChange}              
                        >
                          {userAreaAtuacaoFiltered.map((item) => (
                       <option key={item.id} value={item.id}>
                       {item.descricao}
                       </option>
                     ))}
                          </Select>
                      </Box>
                    ) :(
                      <Box className="p-1 mt-1">
                </Box>
                    )}
                <FormLabel>Status</FormLabel>
                <Select
                  onChange={handleStatusChange}
                  defaultValue={selectedUser?.status}
                  className="mb-3"
                >
                    <>
                      <option value="E">Em análise</option>
                      <option value="P">Pendente</option>
                      <option value="S">Ativo</option>
                      <option value="N">Inativo</option>
                    </>
                </Select>

                {selectedUserStatusChanged && (
                  <Alert status="success">
                    <AlertIcon />
                    Status atualizado com sucesso!
                  </Alert>
                )}
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme="green"
                mr={3}
                onClick={() =>
                  handleEditSubmit(
                    selectedUser?.cadastroCultural,
                    selectedUser?.status,
                    selectedUser?.formularioCultura?.forumPertencente,
                    //forumUpdate,
                    selectedUser?.formularioCultura?.areaAtuacao?.id,
                    selectedUser?.formularioCultura?.areaAtuacaoSecundaria?.id
                  )
                }
              >
                Salvar
              </Button>
              <Button colorScheme="blue" mr={3} onClick={onEditClose}>
                Fechar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Modal isOpen={isViewOpen} onClose={onViewClose} size="5xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Visualização</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <div className="flex justify-center">
                <div className="pr-4">
                  <FormControl isReadOnly className="flex flex-col">
                    <SimpleGrid columns={2}>
                    <Box className="p-1 mt-1">
                        <FormLabel>Nº de Inscrição</FormLabel>
                        <Input
                          type="text"
                          value={selectedUser?.pessoaFisica.cadastroCultural}
                        />
                      </Box>
                      <Box className="p-1 mt-1">
                        <FormLabel>CPF</FormLabel>
                        <Input
                          type="text"
                          value={selectedUser?.pessoaFisica.cpf}
                        />
                      </Box>
                      {selectedUser?.pessoaFisica?.cnpj &&
                        <Box className="p-1 mt-1"> 
                          <FormLabel>CNPJ</FormLabel>
                          <Input
                            type="text"
                            value={selectedUser?.pessoaFisica.cnpj}
                          />
                        </Box>
                      }
                      <Box className="p-1 mt-1">
                        <FormLabel>
                          {selectedUser?.pessoaFisica?.cnpj
                            ? "Nome do responsável"
                            : "Nome"}
                        </FormLabel>
                        <Input
                          type="text"
                          value={
                            selectedUser?.pessoaFisica?.nome
                          }
                        />
                      </Box>
                      {selectedUser?.pessoaFisica?.cnpj &&
                        <Box className="p-1 mt-1">
                          <FormLabel>Razão Social</FormLabel>
                          <Input
                            type="text"
                            value={selectedUser?.pessoaFisica.razaoSocial}
                          />
                        </Box>
                      }
                      <Box className="p-1 mt-1">
                        <FormLabel>Nome Social / Artístico</FormLabel>
                        <Input
                          type="text"
                          value={selectedUser?.pessoaFisica.nomeSocial}
                        />
                      </Box>
                      <Box className="p-1 mt-1">
                        <FormLabel>Data de Nascimento</FormLabel>
                        <Input
                          type="text"
                          value={dateMask(selectedUser?.pessoaFisica.dataNascimento)}
                        />
                      </Box>
                      <Box className="p-1 mt-1">
                        <FormLabel>Email</FormLabel>
                        <Input type="text" value={selectedUser?.pessoaFisica.email} />
                      </Box>
                      <Box className="p-1 mt-1">
                        <FormLabel>Telefone</FormLabel>
                        <Input
                          type="text"
                          value={selectedUser?.pessoaFisica.telefone}
                        />
                      </Box>
                      <Box className="p-1 mt-1">
                        <FormLabel>CEP</FormLabel>
                        <Input type="text" value={selectedUser?.pessoaFisica.cep} />
                      </Box>
                      <Box className="p-1 mt-1">
                        <FormLabel>Endereço</FormLabel>
                        <Input type="text" value={selectedUser?.pessoaFisica.endereco} />
                      </Box>
                      <Box className="p-1 mt-1">
                        <FormLabel>Número</FormLabel>
                        <Input type="text" value={selectedUser?.pessoaFisica.numero} />
                      </Box>
                      <Box className="p-1 mt-1">
                        <FormLabel>Complemento</FormLabel>
                        <Input type="text" value={selectedUser?.pessoaFisica.complementoEndereco} />
                      </Box>
                      <Box className="p-1 mt-1">
                        <FormLabel>Cidade</FormLabel>
                        <Input type="text" value={selectedUser?.pessoaFisica.cidade} />
                      </Box>
                      <Box className="p-1 mt-1">
                        <FormLabel>Bairro</FormLabel>
                        <Input type="text" value={selectedUser?.pessoaFisica.bairro} />
                      </Box>
                      <Box className="p-1 mt-1">
                        <FormLabel>Estado</FormLabel>
                        <Input type="text" value={selectedUser?.pessoaFisica.estado} />
                      </Box>
                      <Box className="p-1 mt-1">
                        <FormLabel>Comprovante Residencia</FormLabel>
                        <Button
                          onClick={() =>
                            downloadFile(
                              selectedUser?.formularioCultura
                                .comprovanteResidencia
                            )
                          }
                          className="border border-gray-200 shadow"
                        >
                          Baixar comprovante
                        </Button>
                      </Box>
                      <Box className="p-1 mt-1">
                        <FormLabel>Cor/Raça/Etnia</FormLabel>
                        <Input
                          type="text"
                          value={
                            selectedUser?.formularioCultura?.raca?.descricao
                          }
                        />
                      </Box>
                      <Box className="p-1 mt-1">
                        <FormLabel>PCD</FormLabel>
                        <Input
                          type="text"
                          value={
                            selectedUser?.formularioCultura
                              ? selectedUser?.formularioCultura?.pcd === true
                                ? "Sim"
                                : "Não"
                              : ""
                          }
                        />
                      </Box>
                      <Box className="p-1 mt-1">
                        <FormLabel>Gênero</FormLabel>
                        <Input
                          type="text"
                          value={
                            selectedUser?.formularioCultura?.genero?.descricao
                          }
                        />
                      </Box>
                      <Box className="p-1 mt-1">
                        <FormLabel>
                          Integrante de comunidade/Povos tradicionais ou
                          originários
                        </FormLabel>
                        <Input
                          type="text"
                          value={
                            selectedUser?.formularioCultura?.comunidade
                              ?.descricao
                          }
                        />
                      </Box>
                      <Box className="p-1 mt-1">
                        <FormLabel>Escolaridade</FormLabel>
                        <Input
                          type="text"
                          value={
                            selectedUser?.formularioCultura?.escolaridade
                              ?.descricao
                          }
                        />
                      </Box>
                      <Box className="p-1 mt-1">
                        <FormLabel>Principal área de atuação</FormLabel>
                        <Input
                          type="text"
                          value={
                            selectedUser?.formularioCultura?.areaAtuacao
                              ?.descricao
                          }
                        />
                      </Box>
                      {selectedUser?.formularioCultura?.areaAtuacao
                        ?.descricao === "Outra" && (
                        <Box className="p-1 mt-1">
                          <FormLabel>Descrição Principal área de atuação</FormLabel>
                          <Input
                            type="text"
                            value={
                              selectedUser?.formularioCultura?.areaAtuacaoPrimariaOutro
                            }
                          />
                        </Box>
                      )}
                      <Box className="p-1 mt-1">
                        <FormLabel>Área de atuação secundária</FormLabel>
                        <Input
                          type="text"
                          value={
                            selectedUser?.formularioCultura
                              ?.areaAtuacaoSecundaria?.descricao
                          }
                        />
                      </Box>
                      {selectedUser?.formularioCultura?.areaAtuacaoSecundaria
                        ?.descricao === "Outra" && (
                        <Box className="p-1 mt-1">
                          <FormLabel>Descrição área de atuação secundária</FormLabel>
                          <Input
                            type="text"
                            value={
                              selectedUser?.formularioCultura?.areaAtuacaoSecundariaOutro
                            }
                          />
                        </Box>
                      )}
                      <Box className="p-1 mt-1">
                        <FormLabel>Renda familiar</FormLabel>
                        <Input
                          type="text"
                          value={
                            selectedUser?.formularioCultura?.rendaFamiliar
                              ?.descricao
                          }
                        />
                      </Box>
                      <Box className="p-1 mt-1">
                        <FormLabel>Forma de trabalho</FormLabel>
                        <Input
                          type="text"
                          value={
                            selectedUser?.formularioCultura?.formaTrabalho
                              ?.descricao
                          }
                        />
                      </Box>
                      <Box className="p-1 mt-1">
                        <FormLabel>Fonte de aprendizagem</FormLabel>
                        <Input
                          type="text"
                          value={
                            selectedUser?.formularioCultura?.fonteAprendizagem
                          }
                        />
                      </Box>
                      <Box className="p-1 mt-1">
                        <FormLabel>Tempo de trabalho</FormLabel>
                        <Input
                          type="text"
                          value={
                            selectedUser?.formularioCultura?.tempoTrabalho
                              ?.descricao
                          }
                        />
                      </Box>
                      <Box className="p-1 mt-1">
                        <FormLabel>Representação atividade cultural</FormLabel>
                        <Input
                          type="text"
                          value={
                            selectedUser?.formularioCultura
                              ?.repAtividadeCultural
                          }
                        />
                      </Box>
                      <Box className="p-1 mt-1">
                        <FormLabel>Dependentes</FormLabel>
                        <Input
                          type="text"
                          value={
                            selectedUser?.formularioCultura
                              ? selectedUser?.formularioCultura?.dependencia
                              : ""
                          }
                        />
                      </Box>
                      <Box className="p-1 mt-1">
                        <FormLabel>
                          Contemplado em ação ou projeto cultural
                        </FormLabel>
                        <Input
                          type="text"
                          value={
                            selectedUser?.formularioCultura
                              ? selectedUser?.formularioCultura?.contemplado ===
                                true
                                ? "Sim"
                                : "Não"
                              : ""
                          }
                        />
                      </Box>
                      <Box className="p-1 mt-1">
                        <FormLabel>Fórum</FormLabel>
                        <Input
                          type="text"
                          value={
                            selectedUser?.formularioCultura?.forumPertencente
                          }
                        />
                      </Box>
                      {selectedUser?.pessoaFisica.cnpj && (
                        <Box className="p-1 mt-1">
                          <FormLabel>Ciclo</FormLabel>
                          <Input
                            type="text"
                            value={
                              selectedUser?.formularioCultura?.cicloArtistico
                            }
                          />
                        </Box>
                      )}
                      <Box className="p-1 mt-1">
                        <FormLabel>Data de inscrição</FormLabel>
                        <Input
                          type="text"
                          value={dateMask(selectedUser?.dataInscricao)}
                        />
                        </Box>
                      <Box className="p-1 mt-1">
                        <FormLabel>Currículo</FormLabel>
                        <Button
                          onClick={() =>
                            downloadFile(
                              selectedUser?.formularioCultura.curriculo
                            )
                          }
                          className="border border-gray-200 shadow"
                        >
                          Baixar currículo
                        </Button>
                      </Box>
                      {userOldFiles.length > 0 && userOldFiles.map((item: any, index) => (
                        <Box className="p-1 mt-1" key={item.codigoArquivo}>
                          <FormLabel>
                            {item.nome === "Outros" ? `Outros ${index + 1}` : item.nome}
                          </FormLabel>
                          <Button className="border border-gray-200 shadow">
                            <a
                              href={`http://www.recife.pe.gov.br/pr/seccultura/fccr/cadastro/${item.url}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Baixar {item.nome === "Outros" ? `Outros ${index + 1}` : item.nome}
                            </a>
                          </Button>
                        </Box>
                      ))}
                    </SimpleGrid>
                  </FormControl>
                </div>
              </div>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={onViewClose}>
                Fechar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </div>
      <div
        style={{ backgroundColor: "#FFC700" }}
        className="w-full h-56 mt-20 relative bottom-0"
      >
        <div className="bg-star bg-no-repeat bg-216 min-h-full bg-center h-full relative -top-40 z-10">
          <div className="mt-8">
            <img
              src={laUrsa}
              className="w-[386px] h-[427px] z-20 absolute right-0 -bottom-[65px] invisible sm:visible"
              alt=""
            />
            <img
              src={caranguejo}
              className="w-56 h-32 absolute left-1/2 top-10 z-30"
              style={{ transform: "translateX(-110%)" }}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admin;
