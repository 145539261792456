import { FormWrapper } from "./FormWrapper";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
} from "@chakra-ui/react";
import { ChangeEvent } from "react";
import { useForm } from "react-hook-form";
import {
  options2,
  optionsAtividadeRepresenta,
  optionsCiclo,
  optionsDependente,
} from "./Opcoes";
import { downloadFile } from "../services/fetchApi";

type CulturalData = {
  atividadeCultural: string;
  dependencia: string;
  ciclo: string;
  contemplado: number;
  forum: string;
  isPessoaJuridica: boolean;
  curriculo: File | null;
};

type CulturalFormProps = CulturalData & {
  updateFields: (fields: Partial<CulturalData>) => void;
  showCurriculumForm: boolean;
};

export const CulturalForm = ({
  atividadeCultural,
  dependencia,
  isPessoaJuridica,
  ciclo,
  contemplado,
  showCurriculumForm,
  forum,
  curriculo,
  updateFields,
}: CulturalFormProps) => {
  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { id, value } = event.target;
    updateFields({ [id]: value });
    if (id === "contemplado") updateFields({ [id]: parseInt(value) });
  };
  const { register } = useForm<CulturalData>();



  return (
    <FormWrapper>
      <FormControl id="atividadeCultural" isRequired>
        <FormLabel className="max-w-xs">
          Em relação ao trabalho, a sua atividade cultural representa:
        </FormLabel>
        <Select
          required
          id="atividadeCultural"
          className="mb-3"
          _hover={{ borderColor: "black" }}
          borderRadius="none"
          borderColor="black"
          w={{ 
            base: "250px",
            sm: "300px"
           }}
          value={atividadeCultural}
          placeholder="Selecione uma opção"
          {...register("atividadeCultural")}
          onChange={handleChange}
        >
          {optionsAtividadeRepresenta.map((atividadeCultural) => (
            <option
              key={atividadeCultural.id}
              value={atividadeCultural.descricao}
            >
              {atividadeCultural.descricao}
            </option>
          ))}
        </Select>
      </FormControl>
      <FormControl id="dependencia" isRequired>
        <FormLabel className="max-w-xs">
          Você tem pessoa(s) que depende(m) do seu trabalho artístico?
        </FormLabel>
        <Select
          required
          id="dependencia"
          className="mb-3"
          _hover={{ borderColor: "black" }}
          borderRadius="none"
          borderColor="black"
          w={{ 
            base: "250px",
            sm: "300px"
           }}
          value={dependencia}
          placeholder="Selecione uma opção"
          {...register("dependencia")}
          onChange={handleChange}
        >
          {optionsDependente.map((dependencia: any) => (
            <option key={dependencia.id} value={dependencia.descricao}>
              {dependencia.descricao}
            </option>
          ))}
        </Select>
      </FormControl>
      {isPessoaJuridica && (
        <FormControl id="ciclo" isRequired>
          <FormLabel>Qual ciclo irá representar?</FormLabel>
          <Select
            required
            id="ciclo"
            className="mb-3"
            _hover={{ borderColor: "black" }}
            borderRadius="none"
            borderColor="black"
            w={{ 
            base: "250px",
            sm: "300px"
           }}
            value={ciclo}
            placeholder="Selecione uma opção"
            {...register("ciclo")}
            onChange={handleChange}
          >
            {optionsCiclo.map((ciclo: any) => (
              <option key={ciclo.id} value={ciclo.descricao}>
                {ciclo.descricao}
              </option>
            ))}
          </Select>
        </FormControl>
      )}

      <FormControl id="contemplado" isRequired>
        <FormLabel className="max-w-sm">
          Você já foi contemplado enquanto proponente em alguma ação/projeto
          cultural da SECULT/FCCR
        </FormLabel>
        <Select
          required
          id="contemplado"
          className="mb-3"
          _hover={{ borderColor: "black" }}
          borderRadius="none"
          borderColor="black"
          w={{ 
            base: "250px",
            sm: "300px"
           }}
          value={contemplado}
          placeholder="Selecione uma opção"
          {...register("contemplado")}
          onChange={handleChange}
        >
          {options2.map((contemplado: any) => (
            <option key={contemplado.id} value={contemplado.id}>
              {contemplado.descricao}
            </option>
          ))}
        </Select>
      </FormControl>

      {showCurriculumForm &&
        forum ===
          "Segmento Artístico (necessário currículo com comprovações)" && (
          <FormControl id="curriculo" isRequired>
            <FormLabel>Currículo (limite de 10Mb)</FormLabel>
            {curriculo ? (
              <>
                <Input
                  value={
                    curriculo instanceof File
                      ? curriculo.name
                      : typeof curriculo === "object"
                      ? "Object"
                      : String(curriculo)
                  }
                  isReadOnly
                  onClick={() => {
                    if (typeof curriculo === "string") {
                      downloadFile(curriculo);
                    }
                  }}
                  className="cursor-pointer"
                  w={{ 
            base: "250px",
            sm: "300px"
           }}
                  height="48px"
                  borderRadius="none"
                  borderColor="black"
                  size="md"
                />
                <Button
                  className="mt-3"
                  colorScheme="red"
                  onClick={() =>
                    updateFields({
                      curriculo: null,
                    })
                  }
                >
                  Remover arquivo
                </Button>
              </>
            ) : (
              <Input
                type="file"
                accept=".pdf, .jpeg, .png"
                className="mb-3"
                w={{ 
            base: "250px",
            sm: "300px"
           }}
                size="lg"
                {...register("curriculo")}
                onChange={(event) => {
                  const files = event.target.files;
                  if (files && files.length > 0) {
                    const file = files[0];
                    const maxSize = 10485760;
                    if (file.size > maxSize) {
                      alert('Tamanho do arquivo excede o limite de 10Mb!');
                      event.target.value = ""
                    }else{
                      updateFields({ curriculo: file });
                    }
                  }
                }}
              />
            )}
          </FormControl>
        )}
    </FormWrapper>
  );
};
