export const optionsAprendizagem = [
    {id: 1, descricao: "Universidade, institutos, academia",},
    {id: 2, descricao: "Oficinas artístico-culturais",},
    {id: 3, descricao: "Aulas particulares",},
    {id: 4, descricao: "Família, amigos, colegas",},
    {id: 5, descricao: "Vivência de trabalho",},
    {id: 6, descricao: "Autodidata"},
    {id: 7, descricao: "Prefiro não informar"},

];

export const optionsAtividadeRepresenta = [
    {id: 1, descricao: "Principal fonte de renda",},
    {id: 2, descricao: "Uma atividade complementar à renda principal",},
    {id: 3, descricao: "Uma atividade recreativa, independente da rentabilidade"},
    {id: 4, descricao: "Prefiro não informar"},
];

export const optionsDependente = [
    {id: 1, descricao: "Sim",},
    {id: 0, descricao: "Não",},
    {id: 2, descricao: "Prefiro não informar"},
];

export const optionsForum = [
    {id: 1, descricao: "Região Política Administrativa (RPA)",},
    {id: 2, descricao: "Segmento Artístico (necessário currículo com comprovações)"},
];

export const optionsCiclo = [
    {id: 1, descricao: "Ciclo Natalino",},
    {id: 2, descricao: "Ciclo Carnavalesco",},
    {id: 3, descricao: "Ciclo Junino"},
];

export const options2 = [
    {id: 1, descricao: "Sim"},
    {id: 0, descricao: "Não"},
  ];
