import { Redirect, useLocation } from "react-router-dom";
import useLogin from "../../hooks/useLogin";
import keycloak from "../../utils/KeycloakUtil";
import { useEffect } from "react";

export interface LoginPageProps {}

const LoginPage = () => {
  const { initialized, authenticated, isAdmin } = useLogin();
  const location = useLocation<{ [key: string]: unknown }>();

  const localAtual = location.state?.from

  sessionStorage.setItem("token", keycloak.token);
  useEffect(() => {
    if (initialized && !authenticated) {
      keycloak.login();
    }
  }, [initialized, authenticated]);
  if (authenticated && isAdmin) {
    return (
      <Redirect
        to={{
          pathname: "/admin",
          state: localAtual,
        }}
      />
    );
  } else if (authenticated && !isAdmin) {
    return (
      <Redirect
        to={{
          pathname: "/cadastrocultural",
          state: {
            localAtual,
            cpf: keycloak?.tokenParsed?.preferred_username
          }
        }}
      />
    );
  }
  return null;
};

export default LoginPage;
