import Keycloak from 'keycloak-js';

const loginUrl = process.env.REACT_APP_LOGIN_URL
const realm = process.env.REACT_APP_KEYCLOAK_REALM
const clientId = process.env.REACT_APP_KEYCLOAK_CLIENT_ID

const keycloak = new Keycloak({
    url: loginUrl,
    realm: realm,
    clientId: clientId,
  });

export default keycloak;