import { ReactNode } from 'react';

type FormWrapperProps = {
  children: ReactNode;
};

export function FormWrapper({ children }: FormWrapperProps) {
  return (
    <>
      <div className="grid gap-4 md:grid-cols-2 max-w-3xl">
        {children}
      </div>
    </>
  );
}
