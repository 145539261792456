import { ReactElement, useState } from 'react';

export function useMultiStepForm(steps: ReactElement[]) {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const stepsDescription = [
    {
      title: 'Dados pessoais',
      description: 'Informe seus dados pessoais',
    },
    {
      title: 'Endereço',
      description: 'Informe seu endereço',
    },
    {
      title: 'Social',
      description: 'Informe seus dados sociais',
    },
    {
      title: 'Trabalho',
      description: 'Informe seus dados profissionais',
    },
    {
      title: 'Cultural',
      description: 'Informe seus dados culturais',
    },
    // {
    //   title: 'Currículo',
    //   description: 'Informe seu currículo',
    // }
  ]


  function next() {
    setCurrentStepIndex(index => {
      if (index >= steps.length - 1) return index
      return index + 1
    })
  }

  function back() {
    setCurrentStepIndex(index => {
      if (index <= 0) return index
      return index - 1
    })
  }

  function goTo(index: number) {
    setCurrentStepIndex(index)
  }

  return {
    currentStepIndex,
    step: steps[currentStepIndex],
    steps,
    stepsDescription,
    isFirstStep: currentStepIndex === 0,
    isLastStep: currentStepIndex === steps.length - 1,
    goTo,
    next,
    back,
  }
}