import { FormProps } from "./schema";
import { useEffect, useState } from "react";
import { useMultiStepForm } from "../hooks/useMultiStepForm";
import { UserForm } from "./UserForm";
import { AddressForm } from "./AddressForm";
import { SocialForm } from "./SocialForm";
import { WorkForm } from "./WorkForm";
import { CulturalForm } from "./CulturalForm";
import { useForm } from "react-hook-form";
import { fetchCadastro, submitForm } from "../services/fetchApi";
import keycloak from "../utils/KeycloakUtil";
import { Dados } from "../interfaces/CadastrosUsuarios";
import useLogin from "../hooks/useLogin";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useMediaQuery,
} from "@chakra-ui/react";
import { AxiosError } from "axios";
import { handleStatusDescritivo } from "../utils/StatusUtil";
import { Link, useLocation } from "react-router-dom";
import { Redirect} from "react-router";



interface DadosForm {
  [key: string]: string | null;
}


const INITIAL_DATA: FormProps = {
  novoCadastroAdmin: false,
  cpf: "",
  cnpj: "",
  nome: "",
  nomeSocial: "",
  razaoSocial: "",
  dataNascimento: "",
  email: "",
  telefone: "",
  endereco: {
    cep: "",
    logradouro: "",
    localidade: "",
    bairro: "",
    numero: "",
    complemento: "",
    uf: "",
    cepComprovante: undefined as unknown as File | null,
  },
  raca: 0,
  pcd: -1,
  genero: 0,
  outroGenero: "",
  comunidade: 0,
  escolaridade: 0,
  areaAtuacaoPrimaria: 0,
  outraAreaAtuacaoPrimaria: "",
  areaAtuacaoSecundaria: 0,
  outraAreaAtuacaoSecundaria: "",
  rendaFamiliar: 0,
  formaTrabalho: 0,
  fonteAprendizagem: "",
  tempoTrabalho: 0,
  atividadeCultural: "",
  dependencia: "",
  contemplado: -1,
  forum: "",
  ciclo: "",
  curriculo: undefined as unknown as File | null,
  isPessoaJuridica: false,
  cnpjExists: false,
  statusCadastro: "",
  cadastroCultural: 0
};

interface LocationState {
  novoCadastroAdmin: boolean;
}
interface Props{
  cpf:string
}

export const MultiStepForm = ({cpf}: Props) => {
  const [data, setData] = useState(INITIAL_DATA);
  const [submittedData, setSubmittedData] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [submitFailed, setSubmitFailed] = useState(false);
  const [msgFailed, setMsgFailed] = useState("Tente novamente mais tarde.");
  const location = useLocation<LocationState>();
  const novoCadastroAdmin = location.state?.novoCadastroAdmin;

 
  useEffect(() => {
    const fetchData = async () => {
     
      let cpfuser = cpf; 
      
      if (cpfuser) {
        
        const cadastro: Dados | null = await fetchCadastro(cpfuser);

        if (cadastro) {
          INITIAL_DATA.novoCadastroAdmin = novoCadastroAdmin;
          INITIAL_DATA.cpf = novoCadastroAdmin ? "" : cadastro.pessoaFisica.cpf ?? "";
          INITIAL_DATA.cnpj = novoCadastroAdmin ? "" : cadastro.pessoaFisica.cnpj ?? "";
          INITIAL_DATA.razaoSocial = novoCadastroAdmin ? "" : cadastro.pessoaFisica.razaoSocial ?? "";
          INITIAL_DATA.nome = novoCadastroAdmin ? "" : cadastro.pessoaFisica.nome ?? "";
          INITIAL_DATA.nomeSocial = novoCadastroAdmin ? "" : cadastro.pessoaFisica.nomeSocial ?? "";
          INITIAL_DATA.dataNascimento = novoCadastroAdmin ? "" : cadastro.pessoaFisica.dataNascimento ?? "";
          INITIAL_DATA.email = novoCadastroAdmin ? "" : cadastro.pessoaFisica.email ?? "";
          INITIAL_DATA.telefone = novoCadastroAdmin ? "" : cadastro.pessoaFisica.telefone ?? "";
          INITIAL_DATA.endereco.cep = novoCadastroAdmin ? "" : cadastro.pessoaFisica.cep ?? "";
          INITIAL_DATA.endereco.logradouro = novoCadastroAdmin ? "" : cadastro.pessoaFisica.endereco ?? "";
          INITIAL_DATA.endereco.localidade = novoCadastroAdmin ? "" : cadastro.pessoaFisica.cidade ?? "";
          //INITIAL_DATA.endereco.localidade = novoCadastroAdmin ? "" : cadastro.pessoaFisica.endereco?.split(', ')[1] ?? "";
          INITIAL_DATA.endereco.bairro = novoCadastroAdmin ? "" : cadastro.pessoaFisica.bairrod ?? "";  
          INITIAL_DATA.endereco.numero = novoCadastroAdmin ? "" : cadastro.pessoaFisica.numero ?? "";  
          INITIAL_DATA.endereco.complemento = novoCadastroAdmin ? "" : cadastro.pessoaFisica.complementoEndereco ?? "";  
          INITIAL_DATA.endereco.uf = novoCadastroAdmin ? "" : cadastro.pessoaFisica.estado ?? "";  
          INITIAL_DATA.endereco.cepComprovante = novoCadastroAdmin ? "" : cadastro.formularioCultura.comprovanteResidencia ?? "";
          INITIAL_DATA.raca = novoCadastroAdmin ? "" : cadastro.formularioCultura.raca?.id ?? "";
          INITIAL_DATA.pcd = novoCadastroAdmin ? -1 : (cadastro.formularioCultura.pcd ? 1 : 0);
          INITIAL_DATA.genero = novoCadastroAdmin ? "" : cadastro.formularioCultura.genero?.id ?? "";
          //INITIAL_DATA.outroGenero = novoCadastroAdmin ? "" : cadastro.formularioCultura.genero.id ?? "";
          INITIAL_DATA.comunidade = novoCadastroAdmin ? "" : cadastro.formularioCultura.comunidade?.id ?? "";
          INITIAL_DATA.escolaridade = novoCadastroAdmin ? "" : cadastro.formularioCultura.escolaridade?.id ?? "";
          INITIAL_DATA.areaAtuacaoPrimaria = novoCadastroAdmin ? "" : cadastro.formularioCultura.areaAtuacao?.id ?? "";
          INITIAL_DATA.outraAreaAtuacaoPrimaria = novoCadastroAdmin ? "" : cadastro.formularioCultura.areaAtuacaoPrimariaOutro ?? "";
          INITIAL_DATA.areaAtuacaoSecundaria = novoCadastroAdmin ? "" : cadastro.formularioCultura.areaAtuacaoSecundaria?.id ?? "";
          INITIAL_DATA.outraAreaAtuacaoSecundaria = novoCadastroAdmin ? "" : cadastro.formularioCultura.areaAtuacaoSecundariaOutro ?? "";
          INITIAL_DATA.rendaFamiliar = novoCadastroAdmin ? "" : cadastro.formularioCultura.rendaFamiliar?.id ?? "";
          INITIAL_DATA.formaTrabalho = novoCadastroAdmin ? "" : cadastro.formularioCultura.formaTrabalho?.id ?? "";
          INITIAL_DATA.fonteAprendizagem = novoCadastroAdmin ? "" : cadastro.formularioCultura.fonteAprendizagem ?? "";
          INITIAL_DATA.tempoTrabalho = novoCadastroAdmin ? "" : cadastro.formularioCultura.tempoTrabalho?.id ?? "";
          INITIAL_DATA.atividadeCultural = novoCadastroAdmin ? "" : cadastro.formularioCultura.repAtividadeCultural ?? "";
          INITIAL_DATA.dependencia = novoCadastroAdmin ? "" : cadastro.formularioCultura.dependencia ?? "";
          INITIAL_DATA.contemplado = novoCadastroAdmin ? -1 :(cadastro.formularioCultura.contemplado ? 1 : 0);
          INITIAL_DATA.forum = novoCadastroAdmin ? "" : cadastro.formularioCultura.forumPertencente ?? "";
          INITIAL_DATA.ciclo = novoCadastroAdmin ? "" : cadastro.formularioCultura.cicloArtistico ?? "";
          INITIAL_DATA.curriculo = novoCadastroAdmin ? "" : cadastro.formularioCultura.curriculo ?? "";
          INITIAL_DATA.isPessoaJuridica = (cadastro.pessoaFisica.cnpj ? true : false);
          INITIAL_DATA.statusCadastro = novoCadastroAdmin ? "" : cadastro.status ?? "";
          INITIAL_DATA.cadastroCultural = novoCadastroAdmin ? 0 : cadastro.cadastroCultural ?? 0;
        } else {
          INITIAL_DATA.cpf = novoCadastroAdmin ? "" : keycloak?.tokenParsed?.preferred_username;
          INITIAL_DATA.nome = novoCadastroAdmin ? "" : keycloak?.tokenParsed?.name;
          INITIAL_DATA.email = novoCadastroAdmin ? "" : keycloak?.tokenParsed?.email;
        }
      }

      return INITIAL_DATA;
    };
    fetchData().then((response) => {
      updateFields(response)
    });
  }, [novoCadastroAdmin, cpf]);

  const updateFields = (fields: Partial<FormProps>) => {
    setData((prev) => {
      return { ...prev, ...fields };
    });
  };

  const {
    stepsDescription,
    currentStepIndex,
    step,
    isFirstStep,
    isLastStep,
    back,
    next,
  } = useMultiStepForm([
    <UserForm {...data} updateFields={updateFields}  />,
    <AddressForm {...data} updateFields={updateFields} />,
    <SocialForm {...data} updateFields={updateFields} />,
    <WorkForm {...data} updateFields={updateFields} />,
    <CulturalForm
      {...data}
      updateFields={updateFields}
      isPessoaJuridica={data.isPessoaJuridica}
      showCurriculumForm={data.forum === "Segmento Artístico (necessário currículo com comprovações)"}
    />,
  ]);

  const { handleSubmit } = useForm();

  async function onSubmit() {
    if (data.cnpjExists) return;

    if (!isLastStep) return next();

    try {
      const formData = new FormData();
      const parsedData = data as unknown as DadosForm;

      for (const key in parsedData) {
        if (key === "endereco") {
          const endereco = data.endereco as DadosForm;
          for (const enderecoKey in endereco) {
            formData.set(
              `endereco.${enderecoKey}`,
              endereco[enderecoKey] as string
            );
          }
        } else {
          formData.set(key, parsedData[key] as string);
        }
      }

      if (data.endereco.cepComprovante) {
        formData.set(
          "endereco.cepComprovante",
          data.endereco.cepComprovante
        );
      }

      if (data.curriculo) {
        formData.set("curriculo", data.curriculo);
      }

      setIsSubmiting(true);
      const response = await submitForm(formData);
      if (response?.status === 200 || response?.status === 201) {
        setSubmittedData(true);
        setSubmitFailed(false);
        setIsSubmiting(false);
        setData(INITIAL_DATA);
        
      } else {
        if (response instanceof AxiosError) {
          setMsgFailed( (response?.response?.data?.message ? response?.response?.data?.message : response?.message) );
        }
        setSubmitFailed(true);
        setTimeout(() => {
          setSubmitFailed(false);
        }, 5000);
        setSubmittedData(false);
        setIsSubmiting(false);
      }

    } catch (error) {
      console.log("erro do form", error);
    }
  }

  const isMobile = useMediaQuery("(max-width: 767px)")[0];
  const { isAdmin } = useLogin();
  return (
    <>
      <div className="max-w-4xl border p-4 rounded-lg border-gray-200 bg-white">
        <Stepper index={currentStepIndex} size="sm" orientation={isMobile ? "vertical" : "horizontal"}>
          {stepsDescription.map((stepDescription: any, index: any) => (
            <Step key={index}>
              <StepIndicator>
                <StepStatus
                  complete={<StepIcon />}
                  incomplete={<StepNumber />}
                  active={<StepNumber />}
                />
              </StepIndicator>
              <Box flexShrink="1">
                <StepTitle>{stepDescription.title}</StepTitle>
                <StepDescription>{stepDescription.description}</StepDescription>
              </Box>
              <StepSeparator />
            </Step>
          ))}
        </Stepper>
      </div>
      <div className="relative bg-white border border-black p-8 m-4 rounded-lg z-50 w-80 sm:w-max font-canada">
        <div className="flex mb-4 justify-between">
        <div>
            {data.cadastroCultural !== 0 && (
              <p className="mt-2">Nº do Cadastro Cultural:
                <span className="ml-2 font-bold">{data.cadastroCultural}</span>
              </p>
              )}
          </div>
          <div>
            {data.statusCadastro !== "" && (
            <p className="mt-2">Status do cadastro:
              <span className="ml-2 font-bold">{ handleStatusDescritivo(data.statusCadastro) }</span>
            </p>
            )}
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
          <div className="top-2 right-2"></div>
          {step}
          
          <div className="flex justify-end mt-4 mb-4 gap-2">
          {isAdmin ? (
            <Link to="/admin" className="flex mb-4 bg-orange-500 text-white font-bold w-max p-2 rounded">
              Home
            </Link>
          ) : (
            <Button type="button" onClick={
              () => {
                keycloak.logout()
              }
            } colorScheme="orange">
              Sair
            </Button>
          )}
            {!isFirstStep && (
              <Button type="button" onClick={back} colorScheme="blue">
                Voltar
              </Button>
            )}
            <Button
              type="submit"
              colorScheme="green"
              isLoading={isSubmiting}
              loadingText="Enviando"
            >
              {isLastStep ? "Finalizar" : "Próximo"}
            </Button>
          </div>
        </form>
        
        {submittedData && !submitFailed && (
         <Redirect
         to={{ pathname: "/cadastrocultural/ok", 
               state:{
                codigo: data.cadastroCultural, 
                nome: data.nome},  }}
       /> 
        )}
        {submitFailed && !submittedData && (
          <Alert
            status="error"
            variant="subtle"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            height="200px"
          >
            <AlertIcon boxSize="40px" mr={0} />
            <AlertTitle mt={4} mb={1} fontSize="lg">
              Erro ao enviar cadastro!
            </AlertTitle>
            <AlertDescription maxWidth="sm">
              {msgFailed}
            </AlertDescription>
          </Alert>
        )}
      </div>
    </>
  );
};
