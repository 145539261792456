import axios from 'axios';

const instace = axios.create({
    baseURL: 'https://viacep.com.br/ws/'
});

export interface ApiResponse {
    cep: string,
    logradouro: string,
    complemento: string,
    bairro: string,
    localidade: string,
    uf: string,
    ibge: string,
    gia: string,
    ddd: string,
    siafi: string
}

const fetchData = async <T>(url: string): Promise<T> => {
    try {
        const response = await instace.get<ApiResponse>(url);
        return response.data as T;
    } catch (error) {
        console.log('Erro ao buscar cep', error)
        return {} as T;
    }
}

export const fetchCep = async (cep: string) => {
    return fetchData<ApiResponse>(`${cep}/json/`);
}
