import { useCallback, useMemo } from "react";
import { useKeycloak } from "@react-keycloak/web";
import IUsuario from "../utils/IUsuario";
import UsuarioUtils from "../utils/UsuarioUtil";

export interface UseLoginState {
  usuario: IUsuario | null;
  initialized: boolean;
  authenticated?: boolean;
  isAdmin: boolean;
  login: any;
}

export default function useLogin(): UseLoginState {
  const { keycloak, initialized } = useKeycloak();

  const usuario = useMemo(() => {
    return UsuarioUtils.getUsuarioPorToken(keycloak.tokenParsed);
  }, [keycloak.tokenParsed]);

  const isAdmin = useMemo(() => {
    return keycloak.tokenParsed?.resource_access?.fccr_front.roles.includes("ADMINISTRADOR") || false;
  }, [keycloak.tokenParsed]);

  const login = useCallback(() => {
    keycloak.login();
  }, [keycloak]);

  return {
    usuario,
    initialized,
    authenticated: keycloak.authenticated,
    isAdmin,
    login,
  };
}
