import { Redirect, Route } from "react-router";
import { RouteProps } from "react-router-dom";
import useLogin from "../hooks/useLogin";

type PaginaProtegidaProps = RouteProps & {
  cadastroCultural?: boolean;
};

const PaginaProtegida = (props: PaginaProtegidaProps) => {
  const { authenticated, isAdmin } = useLogin();
  const localAtual = props.location?.pathname === window.location.pathname;

  if (authenticated) {
     if (!isAdmin && !localAtual) {
      return (
        <Redirect
          to={{ pathname: "/sem_permissao", state: { from: props.location } }}
        />
      );
    } else if (isAdmin && !localAtual) {
      return (
        <Redirect
          to={{ pathname: "/admin", state: { from: props.location } }}
        />
      );
    } else if (props.cadastroCultural && !localAtual) {
      return (
        <Redirect to={{ pathname: "/cadastrocultural", state: { from: props.location } }} />
      );
    } else if (!localAtual) {
      return (
        <Redirect to={{ pathname: "/cadastrocultural/ok", state: { from: props.location } }} />
      );
    }
    return <Route {...props} />;
  }

  return <Redirect to={{ pathname: "/", state: { from: props.location } }} />;
};

export default PaginaProtegida;
